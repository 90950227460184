import React, { useState, useEffect } from "react"
import { Router } from "@reach/router"

import SmartFeedNotFound from "@accrosoft-ltd/vf-careers-site-theme/src/modules/SmartFeedNotFound"

import HomepagePage_Content from "./Homepage_Content"

import fetchJsonp from "fetch-jsonp"

export default function HomepagePage(props) {
  const {
    pageContext: { integratorData, contentMetaData, contentDataData, config },
  } = props

  const siteConfig = config

  console.log(props)

  const [apiKey, setApiKey] = useState(siteConfig.apiKey || props.smartfeedID)
  const [gdprKey, setGdprKey] = useState(siteConfig.gdprKey || props.apiKey || props.smartfeedID)
  const [groupOrIdParam, setGroupOrIdParam] = useState(
    siteConfig.group === true ? "group" : "id"
  )
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({})
  const [appGatewayURL, setAppGatewayURL] = useState({})
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true)
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null)

  useEffect(() => {
    if (apiKey) {
      console.log(apiKey)
      console.log(siteConfig.group)
      let companyDetailsEndpoint = "GetCompanyDetails";

      if (groupOrIdParam === "group") {
        companyDetailsEndpoint = "GetGroupDetails";
      }

      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
          "/CareerPage/" +
          companyDetailsEndpoint +
          "?id=" +
          apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function (response) {
            return response.json()
          })
          .then(function (jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
              "/CareerPage/" +
              "GetApplicantGetwayURL" +
              "?companyId" +
              "=" +
              gdprKey,
              {
                timeout: 15000,
              }
            )
              .then(function (response) {
                return response.json()
              })
              .then(function (appgatway) {
                setVacanciesRouteLoading(false)
                if (jsonList && jsonList.CompanyDetails && jsonList.CompanyDetails.length > 0) {
                  setSmartfeedCompanyData(jsonList.CompanyDetails[0])
                } else {
                  setSmartfeedCompanyData(jsonList)
                }
                setAppGatewayURL(appgatway)
              })
              .catch(function (ex) {
                setVacanciesRouteLoading(false)
                setVacanciesRouteError(ex)
                console.error(ex, "error")
              })
          })
          .catch(function (ex) {
            setVacanciesRouteLoading(false)
            setVacanciesRouteError(ex)
            console.error(ex, "error")
          })
      } catch (ex) {
        setVacanciesRouteLoading(false)
        setVacanciesRouteError(ex)
        console.error(ex, "error")
      }
    } else {
      setVacanciesRouteLoading(false)
      setVacanciesRouteError("No API key was provided.")
    }
  }, [apiKey, gdprKey, groupOrIdParam])

  return (
    <>
      {!siteConfig.companyCareersSite ? (
        <Router>
          <SmartFeedNotFound
            siteConfig={siteConfig}
            location={props.location}
            apiKey={apiKey}
            groupOrIdParam={groupOrIdParam}
            smartfeedCompanyData={smartfeedCompanyData}
            appGatewayURL={appGatewayURL}
            vacanciesRouteLoading={vacanciesRouteLoading}
            vacanciesRouteError={vacanciesRouteError}
            path={
              siteConfig.companyCareersSite
                ? "/vacancies/vacancy/:id"
                : "/vacancies/:smartfeedID/vacancy/:id"
            }
            {...props}
            default
          />
        </Router>
      ) : (
        <HomepagePage_Content
          siteConfig={siteConfig}
          location={props.location}
          apiKey={apiKey}
          groupOrIdParam={groupOrIdParam}
          smartfeedCompanyData={smartfeedCompanyData}
          appGatewayURL={appGatewayURL}
          vacanciesRouteLoading={vacanciesRouteLoading}
          vacanciesRouteError={vacanciesRouteError}
          path={siteConfig.companyCareersSite ? "/" : "/"}
          {...props}
        />
      )}
    </>
  )
}
