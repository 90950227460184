import React, { url, useState } from "react";
import Layout from "@accrosoft-ltd/vf-careers-site-theme/src/components/layout";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import SEO from "@accrosoft-ltd/accropress-websites-components/dist/esm/seo";
import SmartFeedFiltersComponent from "@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedWithFilters_WT";
import WigLoader from "@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader";

import BasicSlider from "@accrosoft-ltd/vf-careers-site-theme/src/components/BasicSlider";
import "@accrosoft-ltd/vf-careers-site-theme/src/components/wig-bundle.css";

export function NoticeTemplate(props) {
  return (
    <span>
      {props.data.attachment &&
        props.data.attachment[0] &&
        props.data.attachment[0].external_url ? (
        <a
          href={props.data.attachment[0].external_url}
          title={props.data.attachment[0].title}
          target="_blank"
        >
          <strong>
            {props.data.title.replace(/&nbsp;/g, "").replace(/&pound;/g, "£")}
            {props.data.snippet
              .replace(/&nbsp;/g, "")
              .replace(/&pound;/g, "£") && ": "}
          </strong>
          {props.data.snippet.replace(/&nbsp;/g, "").replace(/&pound;/g, "£")}
        </a>
      ) : (
        <>
          <strong>
            {props.data.title.replace(/&nbsp;/g, "").replace(/&pound;/g, "£")}
            {props.data.snippet
              .replace(/&nbsp;/g, "")
              .replace(/&pound;/g, "£") && ": "}
          </strong>
          {props.data.snippet.replace(/&nbsp;/g, "").replace(/&pound;/g, "£")}
        </>
      )}
    </span>
  );
}

export default function HomepagePage_Content(props) {
  const {
    pageContext: {
      navResultData,
      integratorData,
      contentMetaData,
      contentDataData,
      config,
    },
  } = props;

  const siteConfig = config;

  console.debug("integrators data: ");
  console.debug(integratorData);

  console.debug("content meta data: ");
  console.debug(contentMetaData);

  console.debug("content data data: ");
  console.debug(contentDataData);

  const [apiKey, setApiKey] = useState(siteConfig.apiKey || props.smartfeedID);
  const [groupOrIdParam, setGroupOrIdParam] = useState(
    siteConfig.group === true ? "group" : "id"
  );
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);

  const [vacancyLoading, setVacancyLoading] = useState(true);
  const [noticesMainLoaded, setNoticesMainLoaded] = useState(false);

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={props.apiKey}
      groupOrIdParam={props.groupOrIdParam}
      smartfeedCompanyData={props.smartfeedCompanyData}
      appGatewayURL={props.appGatewayURL}
      vacanciesRouteLoading={props.vacanciesRouteLoading}
      vacanciesRouteError={props.vacanciesRouteError}
    >
      <SEO
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
      />
      {!props.vacanciesRouteLoading &&
        (siteConfig.siteConfig || props.smartfeedCompanyData) &&
        props.apiKey &&
        props.groupOrIdParam && (
          <>
            <div className="banner page home-banner">
              <div className="half-circle">
                <div className="half-circle-container">
                  <div className="container">
                    <div className="col-12">
                      <div className="text-block">
                        <h1>{contentDataData[0].children[0].value}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {contentDataData[0] &&
                contentDataData[0].children[0] &&
                contentDataData[0].children[1].children &&
                contentDataData[0].children[1].children.length > 0 && (
                  <BasicSlider
                    onBeforeChangeFunction={(type, previous, next) => {
                      return null;
                    }}
                    height="690px"
                    position="relative"
                    backgroundSize="auto"
                    shouldDisplayButtons={true}
                    onBeforeChangeFunction={(type, next) => {
                      return null;
                    }}
                    onBeforeChangeFunction={(type, next) => {
                      return null;
                    }}
                    slides={contentDataData[0].children[1].children}
                  />
                )}
            </div>
            <div className="vacancies">
              <SmartFeedFiltersComponent
                siteConfig={siteConfig}
                setVacancyLoading={setVacancyLoading}
                vacancyLoading={vacancyLoading}
                apiKey={siteConfig.apiKey || props.apiKey}
                group={
                  siteConfig.group
                    ? siteConfig.group
                    : props.groupOrIdParam === "id"
                      ? false
                      : props.groupOrIdParam === "group"
                        ? true
                        : false
                }
                gdprKey={
                  siteConfig.gdprKey || siteConfig.apiKey || props.apiKey
                }
                smartfeedCompanyData={
                  siteConfig.smartfeedCompanyData || props.smartfeedCompanyData
                }
                CompanyGroupName={
                  siteConfig.companyName ||
                  props.smartfeedCompanyData.CompanyName
                }
                OptInLink={
                  siteConfig.companyCareersSite
                    ? `${siteConfig.careersSiteDomain}/job-alerts/optin/?OptInID=`
                    : siteConfig.OptInLink ||
                    `${siteConfig.careersSiteDomain}/job-alerts/${props.apiKey}/optin/?OptInID=`
                }
                UnsubscribeLink={
                  siteConfig.companyCareersSite
                    ? `${siteConfig.careersSiteDomain}/job-alerts/unsubscribe/?UnsubscribeID=`
                    : siteConfig.UnsubscribeLink ||
                    `${siteConfig.careersSiteDomain}/job-alerts/${props.apiKey}/unsubscribe/?UnsubscribeID=`
                }
                VacancyPageBaseURL={
                  siteConfig.companyCareersSite
                    ? `${siteConfig.careersSiteDomain}/vacancies/vacancy/`
                    : siteConfig.VacancyPageBaseURL ||
                    `${siteConfig.careersSiteDomain}/vacancies/${props.apiKey}/vacancy/`
                }
                contentDataData={props.contentDataData || {}}
                location={siteConfig.showLocationJA}
                region={siteConfig.showRegionJA}
                category={siteConfig.showCategoryJA}
                industry={siteConfig.showIndustryJA}
                jobType={siteConfig.showJobTypeJA}
                jobTime={siteConfig.showJobTimeJA}
              />
            </div>
          </>
        )}
    </Layout>
  );
}
